import {isMobile} from 'react-device-detect';
import HeaderMobile from './mobile/Header';
import FooterMobile from './mobile/Footer';
import FooterDesktop from './desktop/Footer';
import HeaderDesktop from './desktop/Header';
import { Helmet } from "react-helmet";

const canonicalUrl = "https://moongig.xyz/user-data-deletion"

const mainDiv = {
    margin: "-8px", 
    fontFamily: "sans-serif"
};

var headerFontSize = "48px";
var contentFontSize = "20px"; 
var sectionDiv = { 
    padding: "40px 100px"
};

if(isMobile){
    headerFontSize = "36px";
    contentFontSize = "16px";     
    sectionDiv = { 
        padding: "20px"
    };
}

const UserDataDeletionPolicy = () => {

    return (
      <div style={mainDiv}>

        {/* helmet */}
        <Helmet>
           <link rel="canonical" href={canonicalUrl} />
         </Helmet>
          {/* banner div */}
          { isMobile ?
            <HeaderMobile />
          :
          <HeaderDesktop />
          }
         <div style={sectionDiv}>
            <p style={{ fontSize: headerFontSize, fontWeight: "bold"}}>User-data deletion policy</p>
            <div style={{ lineHeight: "30px"}}>
                <p style={{ fontSize: contentFontSize}}>Introduction:</p>
                <p style={{ fontSize: contentFontSize}}>This User Data Deletion Policy is in place to comply with Facebook's requirements for apps using Facebook Login, ensuring our commitment to your privacy and data protection.</p>
                <p style={{ fontSize: contentFontSize}}>
                    <ul style={{ listStyle: "none"}}>
                        <li>1. **Data Deletion Request via Email**:<br/><br/>
                        If you wish to delete your data collected by Moongig through Facebook Login, please email a deletion request to  <a href="mailto:privacy@moongig.xyz">privacy@moongig.xyz</a>.<br/>
                        Include the email address associated with your Facebook account and any additional details necessary to identify your account. 
                        </li><br/>
                        <li>
                        2. **Request Processing**:<br/><br/>
                        Upon receiving your email:<br/>
                        a. We will verify your identity to secure your data against unauthorized requests. <br/>
                        b. Your data will be deleted from our records and any third-party services involved. <br/>
                        c. We will confirm the deletion of your data via email. 
                        </li><br/>
                        <li>
                        3. **Deletion Timeline**:<br/><br/>
                        We aim to complete all data deletion requests within 30 days from the verification of your identity. 
                        </li><br/>
                        <li>
                        4.  **Contact for Inquiries**:<br/><br/>
                        If you have any questions about this policy or the deletion process, please contact us at   <a href="mailto:privacy@moongig.xyz">privacy@moongig.xyz</a>.
                        </li>
                    </ul>
                </p>
                <br/>
                <p style={{ fontSize: contentFontSize}}>Note: Data deletion will result in losing access to functionalities within Moongig that require Facebook Login. <br/>
Please consider this before requesting data deletion. We reserve the right to update this policy as needed to reflect changes in our practices or legal requirements, notifying users through our website or email where applicable. Thank you for choosing Moongig. We are dedicated to protecting your privacy and security.
</p>
            </div>
        </div>
          {/* contact div */}
          
          { isMobile ?
            <FooterMobile />
          :
          <FooterDesktop />
          }
      </div>
    );
}

export default UserDataDeletionPolicy;
