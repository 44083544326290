import logo from '../../static/logo-white.svg';


// styles
const logoImg = {
    height: "70px",
    width: "240px"
};

const contactDiv = { 
    marginTop: "36px", 
    padding: "80px 100px",
    backgroundColor: "#23CE85"
};
const contactSubDiv = {display: "flex"};
const contactSubDivTable = { 
    width: '50%',
    textAlign: "left",
    paddingRight: "40px", 
    display: "table"
};
const contactSubDivCell = { 
    display: "table-cell", 
    verticalAlign: "middle"
};
const contactSubDivCont = {
    flexGrow: 1, 
    paddingLeft: "40px",
    display: "table"
};

const contactSubDivHeader1 = { 
    fontSize: "20px", 
    color: "#FFF", 
    lineHeight: "40px",
    paddingTop: "20px"

};
const contactSubDivHeader2 =  {
    fontSize: "20px", 
    color: "#FFF", 
    fontWeight: "600",
    lineHeight: "40px"
};
const contactSubDivAddress =  {
    fontSize: "20px", 
    color: "#FFF", 
    lineHeight: "40px"
};

const FooterDesktop = () => {

  return (
        <div style={contactDiv}>
            
            <div style={contactSubDiv}>
                <div style={contactSubDivTable}>
                    <div style={contactSubDivCell}>
                        <img src={logo} alt="logo" style={logoImg}/>
                        <div style={contactSubDivHeader1}>
                            Operated by
                        </div>
                        <div style={contactSubDivHeader2}>
                            Better Future Ventures Inc. 
                        </div>
                        <div style={contactSubDivAddress}>
                        Unit 1206, 12th flr., Trade & Financial Tower,
                        </div>
                        <div style={contactSubDivAddress}>
                        32nd Street corner 7th Avenue,
                        </div>
                        <div style={contactSubDivAddress}>
                        Bonifacio Global City, Taguig City, Philippines
                        </div>
                    </div>
                </div>
                <div style={contactSubDivCont}>
                    <div style={{textAlign: "left", display: "table-cell", verticalAlign: "bottom"}}>
                        <div style={{ fontSize: "24px",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="/terms" style={{ textDecoration: "none"}}>Terms of Service</a>
                        </div>
                        <div style={{ fontSize: "24px !important",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="https://www.iubenda.com/privacy-policy/83835341" className="iubenda-embed iubenda-noiframe" id="footer-link-privacy-policy">Privacy Policy</a>
                        </div>
                        <div style={{ fontSize: "24px",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="https://www.iubenda.com/privacy-policy/83835341/cookie-policy" className="iubenda-embed iubenda-noiframe" id="footer-link-cookie-policy">Cookie Policy</a>
                        </div>
                        <div style={{ fontSize: "24px",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="/user-data-deletion" style={{ textDecoration: "none"}}>Data Deletion Process</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default FooterDesktop;
