import {isMobile} from 'react-device-detect';
import HeaderMobile from './mobile/Header';
import FooterMobile from './mobile/Footer';
import FooterDesktop from './desktop/Footer';
import HeaderDesktop from './desktop/Header';
import { Helmet } from "react-helmet";

const canonicalUrl = "https://moongig.xyz/policy"

const mainDiv = {
    margin: "-8px", 
    fontFamily: "sans-serif"
};

var headerFontSize = "48px";
var contentFontSize = "20px"; 
var sectionDiv = { 
    padding: "40px 100px"
};

if(isMobile){
    headerFontSize = "36px";
    contentFontSize = "16px";     
    sectionDiv = { 
        padding: "20px"
    };
}

const Policy = () => {

    return (
      <div style={mainDiv}>

        {/* helmet */}
        <Helmet>
           <link rel="canonical" href={canonicalUrl} />
         </Helmet>
          {/* banner div */}
          { isMobile ?
            <HeaderMobile />
          :
          <HeaderDesktop />
          }
          <div style={sectionDiv}>
            <p style={{ fontSize: headerFontSize, fontWeight: "bold"}}>Platform policy</p>
            <div style={{ lineHeight: "30px"}}>
                <p style={{ fontSize: contentFontSize}}>Introduction:</p>
                <p style={{ fontSize: contentFontSize}}>Welcome to Moongig, the premier online platform connecting moonlighting healthcare professionals with healthcare providers in need of their talents. This policy outlines the basic rules and guidelines for using our platform, ensuring a safe and productive environment for all users.

                </p>
                <p style={{ fontSize: contentFontSize, paddingTop: "10px"}}>
                    <ul style={{ listStyle: "none"}}>
                        <li>1. User Eligibility:
Users must be certified healthcare professionals or legally operating healthcare providers to use Moongig. All users are required to provide accurate and current credentials and qualifications.
</li><br/>
                        <li>2. Professional Conduct:
Users are expected to maintain professional conduct at all times, adhering to the highest standards of their profession and ensuring patient confidentiality and care.
</li><br/>
                        <li>3. Data Privacy and Protection:
Moongig is committed to protecting the privacy and security of all user data. Users agree to respect the privacy of others and to protect any personal information obtained through the platform.
</li><br/>
                        <li>4. Platform Use:
Moongig should be used solely for connecting healthcare professionals with opportunities and should not be used for any illegal or unethical activities.
</li><br/>
                        <li>5. Dispute Resolution:
In the event of a dispute between users, Moongig encourages direct resolution. If needed, Moongig will provide mediation services to assist in resolving disputes.
</li><br/>
                        <li>6. Changes to Policy:
Moongig reserves the right to update this policy as necessary to reflect changes in our service or legal requirements. Users will be notified of significant changes.
</li><br/>
                        <li>7. Contact Information:
For questions or concerns regarding this policy or the platform, please contact us at <a href="mailto:support@moongig.xyz">support@moongig.xyz</a>.
</li><br/>
                    </ul>
                </p>
                <br/>
                <p style={{ fontSize: contentFontSize}}>Thank you for being a part of Moongig. Together, we can transform the way healthcare professionals connect with opportunities, fostering growth and enhancing patient care across the healthcare industry.</p>
            </div>
        </div>
          {/* contact div */}
          
          { isMobile ?
            <FooterMobile />
          :
          <FooterDesktop />
          }
      </div>
    );
}

export default Policy;
