import logo from '../../static/logo.svg';
// import background from "../../static/professional/background.png";


// styles
const logoImg = {
    height: "70px",
    width: "240px"
};

const bannerDiv = {
  padding: "70px 100px", 
  backgroundColor: "#161C2D",
  backgroundSize: "cover"
};

const HeaderDesktop = () => {

  return (
    <div style={bannerDiv}>
        <div style={{display: "flex"}}>
            <a href="/"><img src={logo} alt="logo" style={logoImg}/></a>
        </div>
    </div>
  );
}

export default HeaderDesktop;
