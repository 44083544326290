import logo from '../../static/logo-white.svg';


// styles

const contactDiv = { 
    marginTop: "36px", 
    padding: "20px",
    backgroundColor: "#23CE85"
};
const contactLogoImage = {height: "70px",width: "240px"};
const contactSubDivTable = { 
    textAlign: "left",
    display: "table",
    paddingTop: "30px"
};
const contactSubDivCell = { 
    display: "table-cell", 
    verticalAlign: "middle",
    paddingBottom: "50px"
};
const contactSubDivCont = {};
const contactSubDivHeader1 = { 
    fontSize: "20px", 
    color: "#FFF", 
    lineHeight: "40px",
    paddingTop: "20px",

};
const contactSubDivHeader2 =  {
    fontSize: "20px", 
    color: "#FFF", 
    fontWeight: "600",
    lineHeight: "40px",
};
const contactSubDivAddress =  {
    fontSize: "20px", 
    color: "#FFF", 
    lineHeight: "40px",
};

const FooterMobile = () => {

  return (
        <div style={contactDiv}>
            
                <div style={contactSubDivCont}>
                    <div style={{textAlign: "left", paddingTop: "50px", paddingBottom: "50px"}}>
                        <div style={{ fontSize: "24px",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="/terms" style={{ textDecoration: "none"}}>Terms of Service</a>
                        </div>
                        <div style={{ fontSize: "24px !important",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="https://www.iubenda.com/privacy-policy/83835341" className="iubenda-embed iubenda-noiframe" id="footer-link-privacy-policy">Privacy Policy</a>
                        </div>
                        <div style={{ fontSize: "24px",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="https://www.iubenda.com/privacy-policy/83835341/cookie-policy" className="iubenda-embed iubenda-noiframe" id="footer-link-cookie-policy">Cookie Policy</a>
                        </div>
                        <div style={{ fontSize: "24px",  color: "#FFFFFF", marginTop: "10px", lineHeight: "40px"}}>
                            <a href="/user-data-deletion" style={{ textDecoration: "none"}}>Data Deletion Process</a>
                        </div>
                    </div>
                </div>
                <div style={contactSubDivTable}>
                    <div style={contactSubDivCell}>
                        <img src={logo} alt="logo" style={contactLogoImage}/>
                        <div style={contactSubDivHeader1}>
                            Operated by
                        </div>
                        <div style={contactSubDivHeader2}>
                            Better Future Ventures Inc. 
                        </div>
                        <div style={contactSubDivAddress}>
                        Unit 1206, 12th flr., Trade & Financial Tower,
                        </div>
                        <div style={contactSubDivAddress}>
                        32nd Street corner 7th Avenue,
                        </div>
                        <div style={contactSubDivAddress}>
                        Bonifacio Global City, Taguig City, Philippines
                        </div>
                    </div>
                </div>
        </div>
  );
}

export default FooterMobile;
