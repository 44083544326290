import {isMobile} from 'react-device-detect';
import HeaderMobile from './mobile/Header';
import FooterMobile from './mobile/Footer';
import FooterDesktop from './desktop/Footer';
import HeaderDesktop from './desktop/Header';

const mainDiv = {
    margin: "-8px", 
    fontFamily: "sans-serif"
};

var headerFontSize = "48px";
var sectionDiv = { 
    padding: "40px 100px"
};

if(isMobile){
    headerFontSize = "36px";
    sectionDiv = { 
        padding: "20px"
    };
}


const PageNotFound = () => {

    return (
      <div style={mainDiv}>
          {/* banner div */}
          { isMobile ?
            <HeaderMobile />
          :
          <HeaderDesktop />
          }
          <div style={sectionDiv}>
              <p style={{ fontSize: headerFontSize, fontWeight: "bold", paddingTop: "100px",  height: "400px"}}>Page not found</p>
          </div>
          
          { isMobile ?
            <FooterMobile />
          :
          <FooterDesktop />
          }
      </div>
    );
}

export default PageNotFound;
