import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingProfessional from "./pages/LandingProfessional";
import LandingProvider from "./pages/LandingProvider";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import PageNotFound from "./pages/PageNotFound";
import UserDataDeletionPolicy from "./pages/UserDataDeletionPolicy";
import Layout from "./pages/Layout";
import LoginPage from "./pages/LoginPageCustom";
import SignupPage from "./pages/SignupPageCustom";
import { ClerkProvider, AuthenticateWithRedirectCallback } from '@clerk/clerk-react'

const CLERK_KEY = 'pk_test_YXBwYXJlbnQtb3gtNTcuY2xlcmsuYWNjb3VudHMuZGV2JA';
const localization = {
  socialButtonsBlockButtonManyInView: "Continue with {{provider|titleize}}",
  formButtonPrimary: "Continue with email",
  formFieldLabel__emailAddress: "Email",
  signIn : {
    start: {
      title: "Login",
      subtitle: ""
    }
  }
};

export default function App() {
  return (
    <ClerkProvider publishableKey={CLERK_KEY}  localization={localization}   
    appearance={{
      layout: {
        socialButtonsPlacement: 'bottom'
      }
    }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Professional */}
            <Route index element={<LandingProfessional />} />
            {/* Provider */}
            <Route path="provider" element={<LandingProvider />} />
            {/* Terms, Policy pages */}
            <Route path="terms" element={<Terms />} />
            <Route path="policy" element={<Policy />} />
            <Route path="user-data-deletion" element={<UserDataDeletionPolicy />} />
            {/* <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="sso-callback" element={<SSOCallback />} /> */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ClerkProvider>
  );
}

function SSOCallback() {
  // Handle the redirect flow by rendering the
  // prebuilt AuthenticateWithRedirectCallback component.
  // This is the final step in the custom OAuth flow
  return <AuthenticateWithRedirectCallback />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />)